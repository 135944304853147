import React from "react";
import { graphql } from "gatsby";

import Layout from "../../../components/common/layout";
import Footer from "../../../components/common/Footer";
// import Header from "../components/common/Header";

import Artists from "../../../components/artists/Artists";

const UnderneathPage = ({ data, langsMenu, location }) => (
  <Layout location={location}>
    

    <div id="wrapper">
      <section id="main" className="wrapper">
        <div className="inner">
          <h1 className="major">Underneath</h1>
          {/* <span className="image fit">
            <img src={pic4} alt="" />
          </span> */}
        </div>
      </section>
    </div>

    <Artists artists={data.underneathJson.artists} />

    <Footer />
  </Layout>
);

export default UnderneathPage;

export const query = graphql`
  query UnderneathArtistsQuery {
    underneathJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      artists {
        name
        country
        to {
          title
          href
          label
        }
      }
    }
  }
`;
